import Graph from 'react-vis-network-graph';
import {useTranslation} from "react-i18next";

const BrewingGraph = () => {
  const { t } = useTranslation();

  const graph = {
    nodes: [
      { id: 1, label: t("brewingGraph.malt_grinding") },
      { id: 2, label: t("brewingGraph.extracting_sugar") },
      { id: 3, label: t("brewingGraph.Adding_hops") },
      { id: 4, label: t("brewingGraph.wort_cooling") },
      { id: 5, label: t("brewingGraph.adding_yeasts") },
      { id: 6, label: t("brewingGraph.1st_fermentation") },
      { id: 7, label: t("brewingGraph.dry_hopping") },
      { id: 8, label: t("brewingGraph.bottling_keg") },
      { id: 9, label: t("brewingGraph.2nd_fermentation") },
      { id: 10, label: t("brewingGraph.drinking") }
    ],
    edges: [
      { from: 1, to: 2 },
      { from: 2, to: 3 },
      { from: 3, to: 4 },
      { from: 4, to: 5 },
      { from: 5, to: 6 },
      { from: 6, to: 7 },
      { from: 7, to: 8 },
      { from: 8, to: 9 },
      { from: 9, to: 10 }
    ],
    options: {
      nodes: {
        shape: "circle",
        color: {
          border: 'grey',
        },
        font: {
          color:'black'
        },
        imagePadding: {
          left: 10,
          top: 10,
          bottom: 10,
          right: 10
        },
      } // nodes
    }
  };
  const options = {
    layout: {
      hierarchical: true
    },
    edges: {
      color: "#000000"
    },
    height: "1300px",
    width: "100%",
  };

  return (
    <Graph
      graph={graph}
      options={options}
    />
  );
}

export default BrewingGraph;