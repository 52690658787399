import Graph from 'react-vis-network-graph';

const Stout = () => {
  const graph = {
    nodes: [
      { id: 1, label: "Stout", color: "red" },
      { id: 11, label: "American Stout" },
      { id: 12, label: "American\nDouble Stout" },
      { id: 13, label: "Russian\nImperial Stout" },
      { id: 14, label: "Flavored Stout" },
      { id: 15, label: "Sweet/Milk Stout" },
      { id: 16, label: "Irish Stout" },
      { id: 17, label: "Foreign Stout" },

      { id: 2, label: "Porter", color: "brown" },
      { id: 21, label: "American Porter" },
      { id: 22, label: "Baltic Porter" },
      { id: 23, label: "English Porter" }

      // { id: 3, label: "Node 3. Picture clipped", shape: "image", image: "ups.png", imagePadding:4, size: 25 },
      // { id: 4, label: "Node 4 with PNG picture", shape: "image", image: "ups.png", imagePadding:{ top: 10, right: 15, left: 15, bottom: 10}, shapeProperties: { useImageSize: true } },
      // { id: 5, label: "Node 5 with SVG picture", shape: "image", image: "device.svg", imagePadding:5, shapeProperties: { useImageSize: true } }
    ],
    edges: [
      // Stout
      { from: 1, to: 11 },
      { from: 1, to: 12 },
      { from: 1, to: 13 },
      { from: 1, to: 14 },
      { from: 1, to: 15 },
      { from: 1, to: 16 },
      { from: 16, to: 17 },
      // Porters
      { from: 2, to: 21 },
      { from: 2, to: 22 },
      { from: 2, to: 23 }
    ],
    options: {
      nodes: {
        shape: "circle",
        // size: 100,
        color: {
          border: 'grey',

          highlight: {
            border: 'black',
            background: 'white'
          },
          hover: {
            border: 'orange',
            background: 'grey'
          }
        },
        font:{color:'black'},
        // shapeProperties: {
        //   useBorderWithImage:true
        // }
      }
    }
  };
  const options = {
    layout: {
      hierarchical: true
    },
    edges: {
      color: "#000000"
    },
    height: "700px"
  };

  // const events = {
  //   select: function(event) {
  //     let { nodes, edges } = event;
  //   }
  // };

  return (
    <Graph
      graph={graph}
      options={options}
      // events={events}
    />
  );
}

export default Stout;