import * as React from 'react';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import Timeline from '@mui/lab/Timeline';
import TimeLineItem from "./TimeLineItem";

export default function TimeLineComponent() {
  let historyItems = [
    {
      "title" : "~5000BC - Mesopotamia (Iraq/Iran) - Start of a spontaneous fermentation beer brewing"
    },
    {
      "title" : "~4000BC - Egypt, The first receipt for beer brewing"
    },
    {
      "title" : "55 BC - Roman legions introduce beer to Northern Europe."
    },
    {
      "title" : "500-1000 - The first half of the Middle Ages, brewing begins to be practiced in Europe, shifting from family tradition to centralized production in monasteries and convents"
    },
    {
      "title" : "~750 - Hops were first added to beer by monks in monasteries"
    },
    {
      "title" : "1516 - Bavarian brewing guilds push for the Reinheitsgeobot purity laws to make it illegal to use any ingredients but water, barley, and hops in the brewing of beer. Yeast was still unknown at the time."
    },
    {
      "title" : "1721 - First appearance of beer style: Porter"
    },
    {
      "title" : "1759 - Arthur Guinness opened the Guinness Brewery in Dublin, Ireland. The beer market in Ireland and the EU changed forever."
    },
    {
      "title" : "1841 - First appearance of beer style: Marzen/Oktoberfest"
    },
    {
      "title" : "1842 - First appearance of beer style: Pilsen"
    },
    {
      "title" : "1862 - Guinness introduced their trademark label, a buff oval label with the harp and Arthur Guinness's signature."
    },
    {
      "title" : "Late 1800s -  First appearance of beer style: Helles Beers "
    },
    {
      "title" : "1873 - German engineer Carl von Linde invented the first refrigeration system for fermentation tanks."
    },
    {
      "title" : "1876 - The Harp was registered as a trademark of Guinness Brewery. "
    },
    {
      "title" : "1876 - Pasteur unraveled the secrets of yeast in the fermentation process, and he also developed pasteurization to stabilize beers 22 years before the process was applied to milk."
    },
    {
      "title" : "1886 - Guinness became the first major brewery to be incorporated as a public company on the London Stock Exchange"
    },
    {
      "title" : "1920 - Prohibition Law in the USA"
    },
    {
      "title" : "1933 - Prohibition ends for beer (April 7). Only 160 breweries survived"
    },
    {
      "title" : "1935 - The beer can is introduced (American Can Co. & Kreuger Brewing)."
    },
    {
      "title" : "1959 - Draught Guinness was first introduced."
    },
    {
      "title" : "1959 - Guinness began using nitrogen in their beer, and the stout is not the same."
    },
    {
      "title" : "1978 - Microbeer has been legal in the USA, and the beer world is not the same again."
    },
    {
      "title" : "1988 - Draught Guinness in a can was launched, using a widget (ball the size of a ping-pong fill with nitrogen) to recreate the creamy surge. We can enjoy a bar draught Guinness at home too."
    },
    {
      "title":  "Present Days"
    }
  ];
  if (window.localStorage.getItem("i18nextLng")==="he") {
    historyItems = [
      {
        "title" : "~5000BC - מסופוטמיה (עיראק/איראן) - תחילתה של בישול בירה בתסיסה ספונטנית"
      },
      {
        "title" : "~4000BC - מצרים, המתכון הכתוב הראשון לבישול בירה"
      },
      {
        "title" : "55 BC - הלגיונות הרומיים מציגים בירה לצפון אירופה לראשונה."
      },
      {
        "title" : "500-1000 - במחצית הראשונה של ימי הביניים, מתחילים לבשל בירה באירופה, עוברת ממסורת של בישול משפחתי (בדרך כלל האישה עסקה בזה) לייצור ריכוזי במנזרים."
      },
      {
        "title" : "~750 - כשות הוספה לראשונה לבירה על ידי נזירים."
      },
      {
        "title" : "1516 - גילדות מבשלות בוואריה דוחפות לחוקי הטוהר של Reinheitsgebot, האוסר על שימוש של רכיבים אחרים בבישול בירה מלבד מים, שעורה וכשות. שמרים עדיין לא היו ידועים באותה עת."
      },
      {
        "title" : "1721 - הופעה ראשונה של בירה בסגנון: פורטר."
      },
      {
        "title" : "1759 - ארתור גינס פתח את מבשלת גינס בדבלין, אירלנד. שוק הבירה באירלנד ובאיחוד האירופי השתנה לנצח."
      },
      {
        "title" : "1841 - הופעה ראשונה של בירה בסגנון: מרזן/אוקטוברפסט."
      },
      {
        "title" : "1842 - הופעה ראשונה של מבנה סגנון: פילזן."
      },
      {
        "title" : "1862 - גינס הציגה את תווית הסימן המסחרי, תווית עם הנבל והחתימה של ארתור גינס."
      },
      {
        "title" : "סוף שנות ה-1800 - הופעה ראשונה של מבנה בסגנון: Helles Beers."
      },
      {
        "title" : "1873 - המהנדס הגרמני קרל פון לינד המציא את מערכת הקירור הראשונה עבור מיכלי תסיסה."
      },
      {
        "title" : "1876 - הנבל נרשם כסימן מסחרי של מבשלת גינס."
      },
      {
        "title" : "1876 - פסטר גילה את סודות השמרים בתהליך התסיסה, והוא גם פיתח את תהליך הפסטור בירות 22 שנים לפני שהתהליך יושם על חלב."
      },
      {
        "title" : "1886 - גינס הפכה למבשלת הבירה הגדולה הראשונה שהתאגדה כחברה ציבורית בבורסת לונדון."
      },
      {
        "title" : "1920 - חוק היובש (איסור של ייצור, מכירה של מוצרי אלכוהול) בארה\"ב."
      },
      {
        "title" : "1933 - חוק הייבוש מסתיים על בירה (7 באפריל). רק 160 מבשלות שרדו."
      },
      {
        "title" : "1935 - פחית הבירה מוצגת על ידי American Can Co. & Kreuger Brewing."
      },
      {
        "title" : "1959 - דראפט גינס הוצגה לראשונה לציבור."
      },
      {
        "title" : "1959 - גינס התחילו להשתמש בחנקן בבירה שלהם, סטאוט השתנה לתמיד."
      },
      {
        "title" : "1978 - מבשלות הבירה הקטנות (קראפט ביר) הפכו לחוקי בארה\"ב, ועולם הבירה אינו אותו דבר שוב."
      },
      {
        "title" : "1988 - דראפט גינס בפחית הושקה, באמצעות פטנט (כדור בגודל של פינג-פונג מלא בחנקן) כדי ליצור מחדש את הנחשול הקרמי הידוע בבית. עכשיו אנחנו יכולים ליהנות גינס דראפט גם בבית."
      },
      {
        "title":  "ההווה"
      }
    ];
  }

  let showLine = true;
  let items = historyItems.map((item, index) => {
    showLine = index < (historyItems.length-1);

    return <TimeLineItem key={index} text={item.title} showLine={showLine} />
  })

   return (
     <Timeline
       sx={{
         [`& .${timelineItemClasses.root}:before`]: {
           flex: 0,
           padding: 0,
         },
       }}
     >
       { items }
     </Timeline>
   );

}