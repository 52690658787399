import {Accordion} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {Container} from "react-bootstrap";
import General from "./qna/General";
import Equip from "./qna/Equip";
import Receipt from "./qna/Receipt";

// import classes from "./SmallCabinet.module.css";

// import before1 from "../../assets/SmallCabinet/before1web.jpg";

const QNA = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <h1>{t("qna.title")}</h1>
      <p>{t("qna.para")}</p>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t("qna.general.title")}</Accordion.Header>
          <Accordion.Body>
            <General />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>{t("qna.equip.title")}</Accordion.Header>
          <Accordion.Body>
            <Equip />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>{t("qna.receipts.title")}</Accordion.Header>
          <Accordion.Body>
            <Receipt />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default QNA;