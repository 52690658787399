import {Col, Container, Row} from "react-bootstrap";
import { useTranslation } from "react-i18next";

// import classes from "./SmallCabinet.module.css";

import mesoraHydrometer from "../assets/equip/mesora_hydrometer.jpg";
import stirringPeddal from "../assets/equip/stirring_peddal.jpg";
import bottlesTree from "../assets/equip/bottle-drainer-tree.jpg";
import bottleCleaner from "../assets/equip/bottle_cleaner.jpg";


const ExtraEquip = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <h1>{t("extraEquip.title")}</h1>
      <p>{t("extraEquip.para")}</p>
      <Container>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("extraEquip.equipList.item1.title")}</h3>
            <p>{t("extraEquip.equipList.item1.para")}</p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <img src={mesoraHydrometer} alt="plastic container for liquid to use with hydrometer" width="15%" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("extraEquip.equipList.item2.title")}</h3>
            <p>{t("extraEquip.equipList.item2.para")}</p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <img src={stirringPeddal} alt="long stirring pedal" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("extraEquip.equipList.item3.title")}</h3>
            <p>{t("extraEquip.equipList.item3.para")}</p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <img src={bottlesTree} alt="tall plastic tree with branches and a liquid container at the bottom" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("extraEquip.equipList.item4.title")}</h3>
            <p>{t("extraEquip.equipList.item4.para")}</p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <img src={bottleCleaner} alt="plastic push down bottle cleaner" width="15%" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ExtraEquip;