import {Col, Container, Row} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BrewingGraph from "./brewing/BrewingGraph";

import classes from "./BrewingStep.module.css";


const BrewingStep = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <h1>{t("brewingSteps.title")}</h1>
      <p>{t("brewingSteps.para1")}</p>
      <p>{t("brewingSteps.para2")}</p>

      <Container>
        <h3>{t("brewingSteps.subTitle")}</h3>
        <Row>
          <Col sm={12} md={6} lg={6} className={classes.brewingGraph}>
            <BrewingGraph />
          </Col>
          <Col sm={12} md={6} lg={6} >
            <ul>
              <li>
                <h5>{t("brewingSteps.step0.title")}</h5>
                <p>{t("brewingSteps.step0.para")}</p>
              </li>
              <li>
                <h5>{t("brewingSteps.step1.title")}</h5>
                <p>{t("brewingSteps.step1.para")}</p>
              </li>
              <li>
                <h5>{t("brewingSteps.step2.title")}</h5>
                <p>{t("brewingSteps.step2.para")}</p>
              </li>
              <li>
                <h5>{t("brewingSteps.step3.title")}</h5>
                <p>{t("brewingSteps.step3.para")}</p>
              </li>
              <li>
                <h5>{t("brewingSteps.step4.title")}</h5>
                <p>{t("brewingSteps.step4.para")}</p>
              </li>
                <li>
                  <h5>{t("brewingSteps.step5.title")}</h5>
                  <p>{t("brewingSteps.step5.para")}</p>
                </li>
                <li>
                  <h5>{t("brewingSteps.step6.title")}</h5>
                  <p>{t("brewingSteps.step6.para")}</p>
                </li>
                <li>
                  <h5>{t("brewingSteps.step7.title")}</h5>
                  <p>{t("brewingSteps.step7.para")}</p>
                </li>
                <li>
                  <h5>{t("brewingSteps.step8.title")}</h5>
                  <p>{t("brewingSteps.step8.para")}</p>
                </li>
                <li>
                  <h5>{t("brewingSteps.step9.title")}</h5>
                  <p>{t("brewingSteps.step9.para")}</p>
                  <p>{t("brewingSteps.step9.cheers")}</p>
                </li>
              </ul>
          </Col>
        </Row>
      </Container>

      <Container>
        <h3>{t("brewingSteps.methods.title")}</h3>
        <p>{t("brewingSteps.methods.para")}</p>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h4>{t("brewingSteps.methods.extract.title")}</h4>
            <p>{t("brewingSteps.methods.extract.para")}</p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <h4>{t("brewingSteps.methods.allGrain.title")}</h4>
            <p>{t("brewingSteps.methods.allGrain.para")}</p>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <p>{t("brewingSteps.methods.allGrain.biab.title")}</p>
                <p>{t("brewingSteps.methods.allGrain.biab.para")}</p>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <p>{t("brewingSteps.methods.allGrain.mashTun.title")}</p>
                <p>{t("brewingSteps.methods.allGrain.mashTun.para")}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default BrewingStep;