import { useTranslation } from "react-i18next";
import Card from 'react-bootstrap/Card';
import classes from "./Guinness.module.css";

const Guinness02 = () => {
  const { t } = useTranslation();
  let catTitleLang = "float-start";
  if (window.localStorage.i18nextLng==="he") {
    catTitleLang = "float-end"
  }

  return (
    <div>
      <h2>{t("lab.guinness2.title")}</h2>
      <p>{t("lab.guinness2.para1")}</p>

      <p>
        <div className={classes.subTitle}>{t("lab.guinness1.expTitle")}</div>
        {t("lab.guinness2.para2")}<br/><br/>
        <div className={classes.subTitle}>{t("lab.guinness2.predictions.title")}:</div>
        <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.look")}</div>&nbsp; – {t("lab.guinness2.predictions.look")}<br/>
        <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.smell")}</div>&nbsp; – {t("lab.guinness2.predictions.smell")}<br/>
        <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.taste")}</div>&nbsp; – {t("lab.guinness2.predictions.taste")}<br/>
        <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.aftertaste")}</div>&nbsp; – {t("lab.guinness2.predictions.aftertaste")}<br/>
        {t("lab.guinness1.predictions.saleability")}
      </p>

      <div>
        <div className={classes.subTitle}>{t("lab.guinness1.experienceSelf")}</div>
        <div className={classes.twoSplit}>
          <Card>
            <Card.Body>
              <p className={classes.underLine}>* {t("lab.guinness2.israel.title")} -</p>

              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.look")}</div>&nbsp; – {t("lab.guinness2.israel.look")}<br/>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.smell")}</div>&nbsp; – {t("lab.guinness2.israel.smell")}<br/>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.taste")}</div>&nbsp; – {t("lab.guinness2.israel.taste")}<br/>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.aftertaste")}</div>&nbsp; – {t("lab.guinness2.israel.aftertaste")}<br/>
              <div className={classes.subTitle}>{t("lab.guinness1.personalOpinion")}:</div>
              {t("lab.guinness2.israel.POBody")}
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <p className={classes.underLine}>* {t("lab.guinness2.irish.title")} -</p>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.look")}</div>&nbsp; – {t("lab.guinness2.irish.look")}<br/>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.smell")}</div>&nbsp; – {t("lab.guinness2.irish.smell")}<br/>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.taste")}</div>&nbsp; - {t("lab.guinness2.irish.taste")}<br/>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.aftertaste")}</div>&nbsp; – {t("lab.guinness2.irish.aftertaste")}
              <div className={classes.subTitle}>{t("lab.guinness1.personalOpinion")}:</div>
              {t("lab.guinness2.irish.POBody")}
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="mt-3">
        <div className={classes.subTitle}>{t("lab.guinness2.summary.title")}:</div>
        {t("lab.guinness2.summary.body")}
        <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.look")}</div>&nbsp; – {t("lab.guinness2.summary.look")}<br/>
        <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.smell")}</div>&nbsp; – {t("lab.guinness2.summary.smell")}<br/>
        <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.taste")}</div>&nbsp; - {t("lab.guinness2.summary.taste")}<br/>
        <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.aftertaste")}</div>&nbsp; – {t("lab.guinness2.summary.aftertaste")}
        <div className={classes.subTitle}>{t("lab.guinness1.personalOpinion")}:</div>
        {t("lab.guinness2.summary.POBody")}
      </div>
    </div>
  );
}

export default Guinness02;