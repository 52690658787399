import { useTranslation } from "react-i18next";
import Card from 'react-bootstrap/Card';
import classes from "./Guinness.module.css";
const Guinness01 = () => {
  const { t } = useTranslation();
  let catTitleLang = "float-start";
  if (window.localStorage.i18nextLng==="he") {
    catTitleLang = "float-end"
  }

  return (
    <div>
      <h2>{t("lab.guinness1.title")}</h2>
      <p>{t("lab.guinness1.para1")}</p>

      <p>
        <div className={classes.subTitle}>{t("lab.guinness1.expTitle")}</div>
        {t("lab.guinness1.para2")}<br/><br/>
        <div className={classes.subTitle}>{t("lab.guinness1.predictions.title")}:</div>
        <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.look")}</div>&nbsp; – {t("lab.guinness1.predictions.look")}<br/>
        <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.smell")}</div>&nbsp; – {t("lab.guinness1.predictions.smell")}<br/>
        <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.taste")}</div>&nbsp; – {t("lab.guinness1.predictions.taste")}<br/>
        <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.aftertaste")}</div>&nbsp; – {t("lab.guinness1.predictions.aftertaste")}<br/>
        {t("lab.guinness1.predictions.saleability")}
      </p>

      <div>
        <div className={classes.subTitle}>{t("lab.guinness1.experienceSelf")}</div>

        <div className={classes.twoSplit}>
          <Card>
            <Card.Body>
              <p className={classes.underLine}>* {t("lab.guinness1.warm.title")} -</p>

              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.look")}</div>&nbsp; – {t("lab.guinness1.warm.look")}<br/>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.smell")}</div>&nbsp; – {t("lab.guinness1.warm.smell")}<br/>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.taste")}</div>&nbsp; – {t("lab.guinness1.warm.taste")}<br/>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.aftertaste")}</div>&nbsp; – {t("lab.guinness1.warm.aftertaste")}<br/>
              <div className={classes.subTitle}>{t("lab.guinness1.personalOpinion")}:</div>
              {t("lab.guinness1.POBody")}
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <p className={classes.underLine}>* {t("lab.guinness1.cold.title")} -</p>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.look")}</div>&nbsp; – {t("lab.guinness1.cold.look")}<br/>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.smell")}</div>&nbsp; – {t("lab.guinness1.cold.smell")}<br/>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.taste")}</div>&nbsp; - {t("lab.guinness1.cold.taste")}<br/>
              <div className={`${classes.catTitle} ${catTitleLang}`}>{t("lab.guinness1.aftertaste")}</div>&nbsp; – {t("lab.guinness1.cold.aftertaste")}
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="mt-3">
        <div className={classes.subTitle}>{t("lab.guinness1.differences.title")}:</div>
        {t("lab.guinness1.differences.body")}
      </div>
    </div>
  );
}

export default Guinness01;