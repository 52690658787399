import {Accordion} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const General = () => {
  const { t } = useTranslation();

  return (
      <Accordion>
        <Accordion.Item eventKey="01">
          <Accordion.Header>{t("qna.general.q1.q")}</Accordion.Header>
          <Accordion.Body>
            {t("qna.general.q1.a")}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="02">
          <Accordion.Header>{t("qna.general.q2.q")}</Accordion.Header>
          <Accordion.Body>
            {t("qna.general.q2.a")}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="03">
          <Accordion.Header>{t("qna.general.q3.q")}</Accordion.Header>
          <Accordion.Body>
            {t("qna.general.q3.a")}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
  );
}

export default General;