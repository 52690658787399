import { useTranslation } from "react-i18next";

const LabHome = () => {
  const { t } = useTranslation();

  return (
    <div>
      {t("lab.tabHome.para1")}
    </div>
  );
}

export default LabHome;