import React from 'react';
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import classes from './SideBar.module.css';
import LangSelector from "../LangSelector";
import Footer from "../Footer";

const Sidebar = () => {
  const { t } = useTranslation();
  const currentURL = window.location.pathname;

  return (
    <Navbar expand="lg" className={`navbar navbar-dark bg-dark ${classes.fullHeight}`} bg="dark">
      <Container className={classes.columnItems}>
        <Navbar.Brand href="/">
          {t("menu.beer_blog")}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className={classes.columnItems}>
          <Nav className={`me-auto ${classes.columnItems}`} activeKey={currentURL} >
            <Nav.Link href="/History" className={currentURL.includes("History")? classes.activeTag : ""} >{t("menu.history")}</Nav.Link>
            <Nav.Link href="/Types" className={currentURL.includes("Types")? classes.activeTag : ""} >{t("menu.types")}</Nav.Link>
            <Nav.Link href="/Ingredients" className={currentURL.includes("Ingredients")? classes.activeTag : ""} >{t("menu.ingredients")}</Nav.Link>

            <NavDropdown title="Equipment" id="basic-nav-dropdown">
              <NavDropdown.Item href="/Minimum-Equipment" className={currentURL.includes("Minimum-Equipment")? classes.activeTag : ""} >
                {t("menu.min_equip")}
              </NavDropdown.Item>
              <NavDropdown.Item href="/Extra-Equipment" className={currentURL.includes("Extra-Equipment")? classes.activeTag : ""} >
                {t("menu.extra_equip")}
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/Beer-Terminologies" className={currentURL.includes("Beer-Terminologies")? classes.activeTag : ""} >{t("menu.terms")}</Nav.Link>
            <Nav.Link href="/Brewing-Steps" className={currentURL.includes("Brewing-Steps")? classes.activeTag : ""} >{t("menu.brew_steps")}</Nav.Link>
            <Nav.Link href="/Story-time" className={currentURL.includes("Story-time")? classes.activeTag : ""} >{t("menu.story_time")}</Nav.Link>
            <Nav.Link href="/Lab" className={currentURL.includes("Lab")? classes.activeTag : ""} >{t("menu.lab")}</Nav.Link>
            <Nav.Link href="/Books-n-Links" className={currentURL.includes("Books-n-Links")? classes.activeTag : ""} >{t("menu.books_n_links")}</Nav.Link>
            <Nav.Link href="/QA" className={currentURL.includes("QA")? classes.activeTag : ""} style={{marginBottom:'1rem'}} >{t("menu.qa")}</Nav.Link>

            <LangSelector />
          </Nav>

          <Footer />
        </Navbar.Collapse>

      </Container>
    </Navbar>
  );
};

export default Sidebar;