import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Spinner } from 'react-bootstrap'

import './i18n';

ReactDOM.render(
  <Suspense fallback={<Spinner animation="border" />} >
    <App />
  </Suspense>,
  document.getElementById('root')
);

