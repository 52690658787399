// import {Col, Container, Row} from "react-bootstrap";
// import { useTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import {Container} from "react-bootstrap";
// import classes from "./SmallCabinet.module.css";

const Terminologies = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <h1>{t("terms.title")}</h1>
      <h3>{t("terms.sub-title")}</h3>
      <p>{t("terms.para1")} <a href="https://www.alcoholmaster.info/Terminology" _target="blank">Alcohol Master</a>.</p>
      <p>
        <ul>
          <li>
            <h5>{t("terms.list.term1.title")}</h5>
            <p>{t("terms.list.term1.para")}</p>
          </li>
          <li>
            <h5>{t("terms.list.term2.title")}</h5>
            <p>{t("terms.list.term2.para")}</p>
          </li>
          <li>
            <h5>{t("terms.list.term3.title")}</h5>
            <p>{t("terms.list.term3.para")}</p>
          </li>
          <li>
            <h5>{t("terms.list.term4.title")}</h5>
            <p>{t("terms.list.term4.para")}</p>
          </li>
          <li>
            <h5>{t("terms.list.term5.title")}</h5>
            <p>{t("terms.list.term5.para1")}</p>
            <p>{t("terms.list.term5.para2")}</p>
          </li>
          <li>
            <h5>{t("terms.list.term6.title")}</h5>
            <p>{t("terms.list.term6.para")}</p>
          </li>
          <li>
            <h5>{t("terms.list.term7.title")}</h5>
            <p>{t("terms.list.term7.para")}</p>
          </li>
          <li>
            <h5>{t("terms.list.term8.title")}</h5>
            <p>{t("terms.list.term8.para")}</p>
          </li>
          <li>
            <h5>{t("terms.list.term9.title")}</h5>
            <p>{t("terms.list.term9.para")}</p>
          </li>
          <li>
            <h5>{t("terms.list.term10.title")}</h5>
            <p>{t("terms.list.term10.para1")}</p>
            <p>{t("terms.list.term10.para2")}</p>
          </li>
          <li>
            <h5>{t("terms.list.term11.title")}</h5>
            <p>{t("terms.list.term11.para")}</p>
          </li>
          <li>
            <h5>{t("terms.list.term12.title")}</h5>
            <p>{t("terms.list.term12.para")}</p>
          </li>
        </ul>
      </p>
    </Container>
  );
};

export default Terminologies;