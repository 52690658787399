import { useTranslation } from "react-i18next";

import classes from './Welcome.module.css';
import {Container} from "react-bootstrap";

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <Container className={classes.container}>
      <h1>{t("welcome_page.title")}</h1>
      <p>{t("welcome_page.para1")}</p>
      <p>{t("welcome_page.para2")}</p>
      <p>{t("welcome_page.para3")}</p>
      <p>{t("welcome_page.para4")}</p>
      <p>{t("welcome_page.para5")}</p>
      <p>{t("welcome_page.para6")}</p>
      <p>{t("welcome_page.para7")}</p>
    </Container>
  );
};

export default Welcome;