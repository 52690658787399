import { useTranslation } from "react-i18next";
import {Container} from "react-bootstrap";

const Ingredients = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <h1>{t("ingredients.title")}</h1>
      <p>{t("ingredients.para1")}</p>
      <ul>
        <li>
          <h3>{t("ingredients.water.title")}</h3>
          <ul>
            <li>
              <p>{t("ingredients.water.para1")}</p>
            </li>
            <li>
              <p>{t("ingredients.water.para2")}</p>
              <p>{t("ingredients.water.para3")}</p>
            </li>
          </ul>
        </li>
        <li>
          <h3>{t("ingredients.malt.title")}</h3>
          <ul>
            <li>
              <p>{t("ingredients.malt.para1")}</p>
            </li>
            <li>
              <p>{t("ingredients.malt.para2")}</p>
            </li>
            <li>
              <p>{t("ingredients.malt.para3")}</p>
            </li>
          </ul>
        </li>
        <li>
          <h3>{t("ingredients.yeast.title")}</h3>
          <p>{t("ingredients.yeast.para1")}</p>
          <ul>
            <li>
              <p>{t("ingredients.yeast.para1_1")}</p>
            </li>
            <li>
              <p>{t("ingredients.yeast.para1_2")}</p>
            </li>
          </ul>
          <p>{t("ingredients.yeast.para2")}</p>
          <p>{t("ingredients.yeast.para3")}</p>
        </li>
        <li>
          <h3>{t("ingredients.hops.title")}</h3>
          <ul>
            <li>
              <p>{t("ingredients.hops.para1")}</p>
            </li>
            <li>
              <p>{t("ingredients.hops.para2")}</p>
            </li>
            <li>
              <p>{t("ingredients.hops.para3")}</p>
            </li>
          </ul>
        </li>
      </ul>
    </Container>
  );
};

export default Ingredients;