// import {Col, Container, Row} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {Container} from "react-bootstrap";
// import classes from "./SmallCabinet.module.css";

// import before1 from "../../assets/SmallCabinet/before1web.jpg";

const BooksNLinks = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <h1>{t("booksNLinks.title")}</h1>
      <p>{t("booksNLinks.para")}</p>
      <h3>{t("booksNLinks.books.title")}</h3>
      <p>{t("booksNLinks.books.para")}</p>
      <ul>
        <li>
          <h4>{t("booksNLinks.books.item1.title")}</h4>
          <p>{t("booksNLinks.books.item1.para")}</p>
        </li>
        <li>
          <h4>{t("booksNLinks.books.item2.title1")}</h4>
          <h4>{t("booksNLinks.books.item2.title2")}</h4>
          <h4>{t("booksNLinks.books.item2.title3")}</h4>
          <h4>{t("booksNLinks.books.item2.title4")}</h4>
          <p>{t("booksNLinks.books.item2.para")}</p>
        </li>
        <li>
          <h4>{t("booksNLinks.books.item3.title")}</h4>
          <p>{t("booksNLinks.books.item3.para")}</p>
        </li>
      </ul>

      <h3>{t("booksNLinks.sites.title")}</h3>
      <ul>
        <li>{t("booksNLinks.sites.site1")} <a href='http://alcoholmaster.info' rel="noreferrer" target='_blank'>Alcoholmaster.info</a></li>
        {/*<li>{t("booksNLinks.sites.site2")} <a href='https://www.ratebeer.com/' rel="noreferrer" target='_blank'>ratebeer.com</a></li>*/}
        {/*<li>{t("booksNLinks.sites.site3")} <a href='https://beersmithrecipes.com/' rel="noreferrer" target='_blank'>beersmith recipes</a></li>*/}
      </ul>

      {/*<h3>{t("booksNLinks.more.title")}</h3>*/}
      {/*<ul>*/}
      {/*  <li>{t("booksNLinks.more.item1")} <a href='https://www.facebook.com/groups/brewersofisrael/' _target='lank rel="noreferrer"'>*/}
      {/*    {t("booksNLinks.more.urlText1")}*/}
      {/*  </a></li>*/}
      {/*  <li>{t("booksNLinks.more.item2")} <a href='https://www.facebook.com/groups/brewersofisrael/' _target='lank rel="noreferrer"'>*/}
          {/*{t("booksNLinks.more.urlText2")}*/}
      {/*  </a></li>*/}
      {/*</ul>*/}
    </Container>
  );
};

export default BooksNLinks;