import {Col, Container, Row} from "react-bootstrap";
import { useTranslation } from "react-i18next";

// import classes from "./SmallCabinet.module.css";

import plasticFuset from "../assets/equip/plastic-fuset.jpg";
import breather from "../assets/equip/breather.jpg";
import glassTemp from "../assets/equip/glass-termometer.jpg";
import hydrometer from "../assets/equip/hydrometer.jpg";
import bottleFillerTube from "../assets/equip/bottle-filler-tube.jpg";
import bottleCaps from "../assets/equip/caps.jpg";
import emilyCapper from "../assets/equip/emily-capper.jpg";
import benchCapper from "../assets/equip/bench-capper.jpg";

const MinEquip = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <h1>{t("minEquip.title")}</h1>
      <p>{t("minEquip.para")}</p>
      <Container>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item1.title")}</h3>
            <p>{t("minEquip.equipList.item1.para")}</p>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item2.title")}</h3>
            <p>{t("minEquip.equipList.item2.para")}</p>
          </Col>
          <Col>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item3.title")}</h3>
            <p>{t("minEquip.equipList.item3.para")}</p>
          </Col>
          <Col>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item4.title")}</h3>
            <p>{t("minEquip.equipList.item4.para")}</p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <img src={plasticFuset} alt="plastic fuset with a plastic screw connector" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item5.title")}</h3>
            <p>{t("minEquip.equipList.item5.para")}</p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <img src={breather} alt="plastic breather" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item6.title")}</h3>
            <p>{t("minEquip.equipList.item6.para")}</p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <img src={glassTemp} alt="glass thermometer" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item7.title")}</h3>
            <p>{t("minEquip.equipList.item7.para")}</p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <img src={hydrometer} alt="glass hydrometer" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item8.title")}</h3>
            <p>{t("minEquip.equipList.item8.para")}</p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <img src={bottleFillerTube} alt="plastic bottle filler tube" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item9.title")}</h3>
            <p>{t("minEquip.equipList.item9.para")}</p>
          </Col>
          <Col sm={12} md={6} lg={6}>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item10.title")}</h3>
            <p>{t("minEquip.equipList.item10.para")}</p>
          </Col>
          <Col>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item11.title")}</h3>
            <p>{t("minEquip.equipList.item11.para")}</p>
          </Col>
          <Col>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item12.title")}</h3>
            <p>{t("minEquip.equipList.item12.para")}</p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <img src={bottleCaps} alt="various bottle caps with different colors" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item13.title")}</h3>
            <p>{t("minEquip.equipList.item13.para1")}</p>
            <Container>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <p>{t("minEquip.equipList.item13.para2")}</p>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <img src={emilyCapper} alt="handheld capper" width="25%" />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <p>{t("minEquip.equipList.item13.para3")}</p>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <img src={benchCapper} alt="bench capper" />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <h3>{t("minEquip.equipList.item14.title")}</h3>
            <p>{t("minEquip.equipList.item14.para")}</p>
          </Col>
          <Col>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default MinEquip;