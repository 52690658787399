// import {Col, Container, Row} from "react-bootstrap";
// import { useTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import {Container} from "react-bootstrap";

import Ale from './Types/Ale';
import Lager from "./Types/Lager";
import Stout from "./Types/Stout";

// import classes from "./Types.modules.css";

const Types = () => {
  const { t } = useTranslation();


  return (
    <Container >
      <h1>{t("types.title")}</h1>
      <p>{t("types.para1")}</p>
      <p>{t("types.para2")}</p>
      <div>
        <h3>{t("types.ale.title")}</h3>
        <p>{t("types.ale.para")}</p>
        <div className={"graph-div"} >
          <Ale />
        </div>
      </div>
      <div>
        <h3>{t("types.lager.title")}</h3>
        <p>{t("types.lager.para")}</p>
        <div className={"graph-div"} >
          <Lager />
        </div>
      </div>
      <div>
        <h3>{t("types.stout.title")}</h3>
        <p>{t("types.stout.para")}</p>
        <div className={"graph-div"} >
          <Stout />
        </div>
      </div>
    </Container>
  );
};

export default Types;