import { useTranslation } from "react-i18next";
import {Container} from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LabHome from "./lab/LabHome";
import Guinness01 from "./lab/Guinness01";
import Guinness02 from "./lab/Guinness02";

const Lab = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <h1>{t("lab.title")}</h1>
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="home" title={t("lab.title")}>
          <LabHome />
        </Tab>
        <Tab eventKey="profile" title={t("lab.tabGuinness1")} >
          <Guinness01 />
        </Tab>
        <Tab eventKey="contact" title={t("lab.tabGuinness2")} >
          <Guinness02 />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Lab;