import { useTranslation } from "react-i18next";

// import classes from './StoryTime.module.css';
import {Accordion, Container} from "react-bootstrap";

const StoryTime = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <h1>{t("storyTime.title")}</h1>
      <p>{t("storyTime.para")}</p>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t("storyTime.story1.title")}</Accordion.Header>
          <Accordion.Body>
            {t("storyTime.story1.body")}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>{t("storyTime.story2.title")}</Accordion.Header>
          <Accordion.Body>
            {t("storyTime.story2.body")}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>{t("storyTime.story3.title")}</Accordion.Header>
          <Accordion.Body>
            {t("storyTime.story3.body")}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

    </Container>
  );
};

export default StoryTime;