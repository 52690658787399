import {useEffect} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import SideBar from "./components/SideBar/SideBar";
import Welcome from "./pages/Welcome";
import History from "./pages/History";
import Types from "./pages/Types";
import Ingredients from "./pages/Ingredients";
import Terminologies from "./pages/Terminologies";
import BrewingStep from "./pages/BrewingStep";
import MinEquip from "./pages/MinEquip";
import ExtraEquip from "./pages/ExtraEquip";
import QNA from "./pages/QNA";
import BooksNLinks from "./pages/BooksNLinks";
import StoryTime from "./pages/StoryTime";
import Lab from "./pages/Lab";
import ErrorPage from "./components/ErrorPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from './App.module.css';


const App = () => {

  useEffect(() => {
    const isBrowserCompatible = checkBrowserCompatibility();
    if (!isBrowserCompatible) {
      alert('Your browser is not supported. Please use a modern browser.');
    }
  }, []);

  const checkBrowserCompatibility = () => {
    // Check for browser features and compatibility here
    // You can use feature detection methods or check for specific browser versions

    // Example: Check if the browser supports the 'fetch' API
    if (!window.fetch) {
      return false;
    }

    // Add more compatibility checks as needed

    // If all checks pass, return true
    return true;
  };

  return (
    <>
    <Router>
      <div className={classes.main}>

          <div className="sideBar">
            <SideBar />
          </div>

          <div className={classes.contentBlock}>
            <Routes>
              <Route path="/" element={<Welcome />} />
              <Route path="/home" exact={true} element={<Welcome />} />
              <Route path="/Types" exact={true} element={<Types />} />
              <Route path="/History" exact={true} element={<History />} />
              <Route path="/Ingredients" exact={true} element={<Ingredients />} />
              <Route path="/Beer-Terminologies" exact={true} element={<Terminologies />} />
              <Route path="/Brewing-Steps" exact={true} element={<BrewingStep />} />
              <Route path="/Minimum-Equipment" exact={true} element={<MinEquip />} />
              <Route path="/Extra-Equipment" exact={true} element={<ExtraEquip />} />
              <Route path="/Story-time" exact={true} element={<StoryTime />} />
              <Route path="/Lab" exact={true} element={<Lab />} />
              <Route path="/Books-n-Links" exact={true} element={<BooksNLinks />} />
              <Route path="/QA" exact={true} element={<QNA />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </div>
      </div>
    </Router>
      {/*<Footer />*/}
    </>
  );
};

export default App;
