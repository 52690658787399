import classes from './Footer.module.css';

const Footer = () => {
  const className = classes.footer;

  return (
    <footer className={className}>
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
        &copy; {new Date().getFullYear()} Copyright: Roy Keren
      </span>
    </footer>
  );
};

export default Footer;