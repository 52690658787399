import Graph from 'react-vis-network-graph';

const Ale = () => {
  const graph = {
    nodes: [
      { id: 1, label: "Ale", color: "yellow" },
      { id: 2, label: "Mild Ale" },
      { id: 21, label: "English Brown Ale" },
      { id: 22, label: "American Brown Ale" },
      { id: 4, label: "American Wild Ale" },
      { id: 5, label: "Lambic" },
      { id: 6, label: "Belgian/French Ale" },
      { id: 61, label: "Saison" },
      { id: 62, label: "Flanders Red Ale" },
      { id: 63, label: "Oud Bruin" },
      { id: 66, label: "Belgian Pale Ale" },
      { id: 661, label: "Belgian Strong Pale Ale" },
      { id: 6611, label: "Tripel" },
      { id: 67, label: "Belgian Dark Ale" },
      { id: 671, label: "Belgian Strong Dark Ale" },
      { id: 6711, label: "Quadrupel" },
      { id: 6712, label: "Dubbel" },
      { id: 68, label: "Witbier" },
      { id: 7, label: "Rye Ale" },
      { id: 8, label: "German Ale" },
      { id: 82, label: "Weissbier" },
      { id: 821, label: "Hefeweizen" },
      { id: 822, label: "Gose" },
      { id: 823, label: "Berliner Weisse" },
      { id: 824, label: "Kristal Weizen" },
      { id: 83, label: "Altbier" },
      { id: 84, label: "Kolsch" },
      { id: 11, label: "Irish Ale" },
      { id: 12, label: "Pale Ale" },
      { id: 121, label: "English Pale Ale" },
      { id: 1212, label: "Bitter" },
      { id: 12121, label: "Premium Bitter/ESB" },
      { id: 122, label: "India Pale Ale" },
      { id: 1221, label: "Black IPA" },
      { id: 1222, label: "Double IPA" },
      { id: 123, label: "Strong Pale Ale" },
      { id: 1231, label: "English Strong Ale" },
      { id: 1232, label: "American Strong Ale" },
      { id: 1233, label: "Scotch Ale" },
      { id: 124, label: "American Pale Ale" }
    ],
    edges: [
      { from: 1, to: 2 },
      { from: 1, to: 4 },
      { from: 1, to: 5 },
      { from: 1, to: 6 },
      { from: 1, to: 7 },
      { from: 1, to: 8 },
      { from: 1, to: 11 },
      { from: 1, to: 12 },
      { from: 1, to: 300 },
      { from: 2, to: 21 },
      { from: 2, to: 22 },
      { from: 6, to: 61 },
      { from: 6, to: 62 },
      { from: 6, to: 63 },
      { from: 6, to: 66 },
      { from: 66, to: 661 },
      { from: 661, to: 6611 },
      { from: 66, to: 67 },
      { from: 67, to: 671 },
      { from: 67, to: 6711 },
      { from: 67, to: 6712 },
      { from: 66, to: 68 },
      { from: 8, to: 82 },
      { from: 82, to: 821 },
      { from: 82, to: 822 },
      { from: 82, to: 823 },
      { from: 82, to: 824 },
      { from: 8, to: 83 },
      { from: 8, to: 84 },
      { from: 12, to: 121 },
      { from: 121, to: 1212 },
      { from: 1212, to: 12121 },
      { from: 12, to: 122 },
      { from: 122, to: 1221 },
      { from: 122, to: 1222 },
      { from: 12, to: 123 },
      { from: 12, to: 1231 },
      { from: 12, to: 1232 },
      { from: 12, to: 1233 },
      { from: 12, to: 124 },
    ],
    options: {
      nodes: {
        shape: "circle",
        color: {
          border: 'grey',

          highlight: {
            border: 'black',
            background: 'white'
          },
          hover: {
            border: 'orange',
            background: 'grey'
          }
        },
        font: {
          color:'black'
        }
      }
    }
  };
  const options = {
    layout: {
      hierarchical: true
    },
    edges: {
      color: "#000000"
    },
    height: "700px",
    backgroundColor: "#f0f0f0"
  };

  // const events = {
  //   select: function(event) {
  //     let { nodes, edges } = event;
  //   }
  // };

  return (
    <Graph
      graph={graph}
      options={options}
      // events={events}
    />
  );
}

export default Ale;