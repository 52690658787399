import Graph from 'react-vis-network-graph';

const Lager = () => {
  const graph = {
    nodes: [
      { id: 1, label: "Lager", color: "orange" },
      { id: 11, label: "American Lager" },
      { id: 111, label: "American\nPale Lager" },
      { id: 112, label: "American\nDark/Amber Lager" },
      { id: 12, label: "German Lager" },
      { id: 121, label: "Vienna Lager" },
      { id: 122, label: "Rauchbier" },
      { id: 123, label: "Bock" },
      { id: 1231, label: "Helles/Maibock" },
      { id: 1232, label: "Doppelbock" },
      { id: 1233, label: "Eisbock" },
      { id: 1234, label: "Dunkler Bock" },
      { id: 124, label: "Marzen" },
      { id: 125, label: "Schwarzbier" },
      { id: 126, label: "Munich Lager" },
      { id: 1261, label: "Munich Helles" },
      { id: 1262, label: "Munich Dunkel" },
      { id: 127, label: "Keller Bier" },
      { id: 13, label: "European Lager" },
      { id: 14, label: "European\nStrong Lager" },
      { id: 15, label: "Pilsner" },
      { id: 151, label: "American Pilsner" },
      { id: 152, label: "Bohemian Pilsner" },
      { id: 153, label: "German Pilsner" },
    ],
    edges: [
      // Lager
      { from: 1, to: 11 },
      { from: 11, to: 111 },
      { from: 11, to: 112 },
      { from: 1, to: 12 },
      { from: 12, to: 121 },
      { from: 12, to: 122 },
      { from: 12, to: 123 },
      { from: 123, to: 1231 },
      { from: 123, to: 1232 },
      { from: 123, to: 1233 },
      { from: 123, to: 1234 },
      { from: 12, to: 124 },
      { from: 12, to: 125 },
      { from: 12, to: 126 },
      { from: 126, to: 1261 },
      { from: 126, to: 1262 },
      { from: 12, to: 127 },
      { from: 1, to: 13 },
      { from: 1, to: 14 },
      { from: 1, to: 15 },
      { from: 15, to: 151 },
      { from: 15, to: 152 },
      { from: 15, to: 153 },
      { from: 1, to: 16 },
    ],
    options: {
      nodes: {
        shape: "circle",
        // size: 100,
        color: {
          border: 'grey',

          highlight: {
            border: 'black',
            background: 'white'
          },
          hover: {
            border: 'orange',
            background: 'grey'
          }
        },
        font:{color:'black'},
        // shapeProperties: {
        //   useBorderWithImage:true
        // }
      }
    }
  };
  const options = {
    layout: {
      hierarchical: true
    },
    edges: {
      color: "#000000"
    },
    height: "700px"
  };

  // const events = {
  //   select: function(event) {
  //     let { nodes, edges } = event;
  //   }
  // };

  return (
    <Graph
      graph={graph}
      options={options}
      // events={events}
    />
  );
}

export default Lager;