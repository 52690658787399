import {Accordion} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const Receipt = () => {
  const { t } = useTranslation();

  return (
    <Accordion>
      <Accordion.Item eventKey="21">
        <Accordion.Header>{t("qna.receipts.q1.q")}</Accordion.Header>
        <Accordion.Body>
          {t("qna.receipts.q1.a")} <a href="https://beersmithrecipes.com/" target="_blank" >Beersmith recipes</a>
        </Accordion.Body>
      </Accordion.Item>
      {/*<Accordion.Item eventKey="22">*/}
      {/*  <Accordion.Header>Accordion Item #2</Accordion.Header>*/}
      {/*  <Accordion.Body>*/}
      {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
      {/*    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim*/}
      {/*    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea*/}
      {/*    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate*/}
      {/*    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat*/}
      {/*    cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id*/}
      {/*    est laborum.*/}
      {/*  </Accordion.Body>*/}
      {/*</Accordion.Item>*/}
    </Accordion>
  );
}

export default Receipt;