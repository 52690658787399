import { useTranslation } from "react-i18next";

import classes from './Welcome.module.css';
import {Container} from "react-bootstrap";
import TimeLineComponent from '../components/TimeLine/TimeLineComponent';

const History = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <h1>{t("history.title")}</h1>
      <p>{t("history.para1")}</p>
      <p>{t("history.para2")}</p>

      <div className={classes.timeLineCenter}>
        <TimeLineComponent />
      </div>
    </Container>
  );
};

export default History;